body, html {
  background: radial-gradient(circle, #082032 0%, #061724 82%);;
  height: 100%;
  width: 100%;
}

.App, #root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  --first-fret-color: #222831;
  --fret-color: #2C394B;
  --fret-division-color: #fff;
  --guitar-color: #FF4C29;
  --string-color: #DDDDDD;
  overflow: hidden;
}

.starter {
  background: rgba(0,0,0,0.8);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: +5;
  font-size: 3em;
  letter-spacing: .1em;
}

.starter.hide {
  display: none;
}

.controls {
  width: 100%;
  max-width: 1000px;
  box-sizing: border-box;
  padding: 0 120px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
}

.controls .stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.controls .modes {
  color: var(--guitar-color);
  font-weight: bold;
  display: flex;
}

.modes .mode {
  padding: 8px 16px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.4);
  font-size: 1.2em;
  border-radius: 8px;
  letter-spacing: 0.1em;
  margin-left: 16px;
  cursor: pointer;
  transition: all .3s linear;
}
.modes .mode:hover {
  background-color: var(--fret-division-color);
  color: var(--fret-color);
  text-shadow: 1px 1px 0px rgba(0,0,0,0);
  opacity: .7;
}
.modes .mode.selected {
  background-color: var(--guitar-color);
  color: var(--fret-color);
  text-shadow: 1px 1px 0px rgba(0,0,0,0);
}

.currentNoteStats {
  display: flex;
  flex-direction: column;
}

.currentNoteStats .note {
  font-size: 6em;
  color: var(--guitar-color);
  font-weight: bold;
}

.currentNoteStats .hz {
  font-size: 2em;
  color: var(--fret-division-color);
  font-weight: bold;
}

.currentNoteStats .err {
  color: var(--guitar-color);
}

.fretboard {
  width: 100vw;
  height: 180px;
  display: flex;
  /* flex-direction: column; */
  box-shadow: 0px 16px 32px rgba(0,0,0,.5);
  position: relative
}
.fretWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  background-color: var(--fret-color);
  /* background-image: linear-gradient(360deg, #69140e 0%, #5d1e0f 74%); */
  z-index: +1;
  border: 1px solid var(--fret-division-color);
  border-left: 0;
  border-right: 0;
  box-shadow: inset 0px 0px 4px rgba(0,0,0,1);
}

.fretWrapper:nth-child(1) {
  background: var(--first-fret-color);
  border-color: transparent;
  flex: 1;
}
.fretWrapper {
  flex: 2.2;
}

.fretWrapper:nth-child(n+6) {
  flex: 1.9;
}

.fretWrapper:nth-child(n+9) {
  flex: 1.7;
}

.fretWrapper:nth-child(n+11) {
  flex: 1.5;
}

.fretWrapper:nth-child(n+14) {
  flex: 1.3;
}

.fretWrapper:nth-child(n+18) {
  flex: 1;
}

.dotsWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.dot {
  height: 20px;
  width: 20px;
  background-color: #edf5fc;
  border-radius: 100%;
  opacity: 0.6;
}

.fret {
  position: relative;
  z-index: +1;
  flex: 1;
}

.fret::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 6px;
  background: var(--fret-division-color);
  box-shadow: 4px 0px 4px rgba(0,0,0,0.2);
}
.fretWrapper:nth-child(1) .fret::after {
  width: 12px;
}

.string {
  width: 100%;
  --size: 2.5px;
  height: var(--size);
  position: absolute;
  background-color: var(--string-color);
  background-image: linear-gradient(360deg, var(--string-color) 0%, rgba(0,0,0,0.2) 74%);
  top: calc(50% - (var(--size) / 2));
  z-index: 1;
  box-shadow: 0px 4px 2px rgba(0,0,0,0.3);
}
.string.vibrate {
  animation: vibrate .3s infinite;
}
.fret:nth-child(1) .string{
  --size: 5px
}

.fret:nth-child(2) .string{
  --size: 4.7px
}

.fret:nth-child(3) .string{
  --size: 4.2px
}

.fret:nth-child(4) .string{
  --size: 4px
}

.fret:nth-child(5) .string{
  --size: 3px
}

.noteOnString {
  --size: 24px;
  background: #23ce6b;
  height: var(--size);
  width: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: absolute;
  border-radius: 100%;
  font-size: 14px;
  font-weight: bold;
  top: calc(50% - (var(--size) / 2));
  left: calc(50% - (var(--size) / 2));
  border: 1px solid #000;
  transition: all .3s ease;
}

.noteOnString.currentNote {
  --size: 28px;
  opacity: 0.8;
  transition: all .2s ease;
  background: #edf5fc;
  box-shadow: 0px 0px 40px rgba(255,255,255,0.8);
}
.noteOnString.currentNote.wrong {
  background: #ce2326;
  opacity: .5;
  transition: all 1s ease;
}
.noteOnString.currentNote.hide {
  opacity: 0;
  transition: all 1s ease;
}

.notes {
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  margin: 16px 0;
  align-self: center;
}

.noteControl {
  height: 40px;
  width: 40px;
  background: rgba(0,0,0,0.2);
  color: #fff;
  box-shadow: 1px 1px 8px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin: 8px;
  transition: all .3s linear;
  cursor: pointer;
  border: 0px solid transparent;
  transition: all .5s ease;
  font-weight: bold;
  border: 4px solid rgba(0,0,0,0);
  opacity: 0.2;
}

.noteControl.selected, .noteControl:hover {
  border: 4px solid rgba(0,0,0,0.5);
  box-shadow: 0px 0px 12px rgba(0,0,0,0);
  opacity: 1;
}

.a {
  background: #ce4023;
  color: #000;
}
.asharp {
  background: #ce7923;
  color: #000;
}
.b {
  background: #c8ce23;
  color: #000;
}
.c {
  background: #70ce23;
  color: #000;
}
.csharp {
  background: #23ce81;
  color: #000;
}
.d {
  background: #23a6ce;
  color: #000;
}
.dsharp {
  background: #2340ce;
  color: #fff;
}
.e {
  background: #4523ce;
  color: #fff;
}
.f {
  background: #8f23ce;
  color: #fff;
}
.fsharp {
  background: #cb23ce;
  color: #fff;
}
.g {
  background: #ce23c5;
  color: #fff;
}
.gsharp {
  background: #ce2326;
  color: #fff;
}
.unimportantnote {
  background: #000;
  color: #fff;
}

.wood {
  height: 400%;
  width: 800px;
  background: var(--guitar-color);
  position: absolute;
  right: -600px;
  top: -150%;
  border-radius: 25%;
  box-shadow: 0px 8px 16px rgba(0,0,0,1);
}
.wood div {
  height: 50%;
  border-radius: 100%;
  background: rgba(0,0,0,0.1);
  aspect-ratio: 1;
}

@keyframes vibrate {
  0% {
    top: 0px
  }
  25% {
    top: 2px
  }
  75% {
    top: -2px
  }
}

.madeby {
  position: fixed;
  bottom: 16px;
  left: 16px;
  color: #edf5fc87;
  font-size: 1.2em;
  letter-spacing: .1em;
  z-index: +6;
}

.madeby b {
  color: var(--guitar-color);
}

.madeby u {
  color: var(--fret-division-color);
}


.desktopOnly {
  display: none;
}

@media only screen and (max-width: 600px) {
  .desktopOnly {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--first-fret-color);
    color: var(--fret-division-color);
    z-index: +6;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
  }
  .desktopOnly h1 {
    margin: 0;
    font-size: 6em;;
  }
  .desktopOnly h2 {
    font-size: 1.5em;
    font-weight: normal;
  }
  .desktopOnly i {
    color: var(--guitar-color);
  }
}